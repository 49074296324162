import css from 'styled-jsx/css'
import { useEffect, useState } from 'react'
import ImageCarousel from '@ui/components/shared/ImageCarousel'
import { SyncedProp, useIsPreview, useSetting, useUpdateEffect } from '@ui/hooks'
import { thumbImage } from '@libs/client/helpers/imagePath'
import {
  imagePath,
  previewImage,
  videoPath,
  previewVideo,
  videoPathFallback,
  useCdnBasePath
} from '@ui/helpers'
import LazyLoad, { ILazyLoadOptions } from 'vanilla-lazyload'

type ProductImagesProps = {
  changedVariantImage: number
  activeIndexSyncedProp: SyncedProp<number>
  isNormalBattery: boolean
}

type mediaType =
  | 'image'
  | 'fallback-image'
  | 'image-thumb'
  | 'video'
  | 'fallback-gif'
  | 'gif-thumb'
  | 'gif-thumb-poster'

function hashCode(str: string) {
  return str
    ?.split('')
    ?.reduce((prevHash, currVal) => ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0, 0)
}

// const mThumb = thumbImage('w=500,h=500')

declare global {
  interface Document {
    lazyLoadInstance?: any
  }
}

const options: ILazyLoadOptions = {
  elements_selector: '.lazy'
}
const sThumb = thumbImage('96x96')
// export default function ProductImages({
export default function ProductImages({
  changedVariantImage,
  activeIndexSyncedProp,
  isNormalBattery
}: ProductImagesProps) {
  const basepath = useCdnBasePath()
  const [sellpageSetting] = useSetting('sellpage')
  const [syncedActiveIndex, setSyncedActiveIndex] = activeIndexSyncedProp
  const isPreview = useIsPreview()
  const imgUrls = sellpageSetting?.gallery || []
  const [isMounted, setIsMounted] = useState(false)
  const [storeSetting] = useSetting('store')
  const version = storeSetting?.version
  // const cacheImages = useRef<number[]>([0])
  const [cacheImages, setCacheImages] = useState([0])
  useEffect(() => {
    setIsMounted(true)
    setSyncedActiveIndex(0)
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined' && !document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad(options)
    }
  }, [])

  useUpdateEffect(() => {
    if (version == 'vap1' && imgUrls[syncedActiveIndex]?.includes('.mp4')) {
      if (document.lazyLoadInstance) {
        document.lazyLoadInstance.update()
      } else {
        document.lazyLoadInstance = new LazyLoad(options)
      }
    }
    if (!cacheImages.includes(syncedActiveIndex)) {
      setCacheImages([...cacheImages, syncedActiveIndex])
    }
  }, [syncedActiveIndex])

  const changeImage = (index: number) => {
    setSyncedActiveIndex(index)
  }

  const getPathMedia = (type: mediaType, index: number, size = '') => {
    if (isPreview)
      return type == 'image' || type == 'image-thumb'
        ? previewImage(imgUrls[index])
        : previewVideo(imgUrls[index], basepath) || ''
    switch (type) {
      case 'image':
        return imagePath(imgUrls[index])
      case 'fallback-image':
        return imagePath(sellpageSetting?.fallback_img?.[imgUrls[index]] || '')
      case 'image-thumb':
        return sThumb(imgUrls[index])
      case 'video':
        return videoPath(imgUrls[index], basepath)
      case 'fallback-gif':
        return videoPathFallback(sellpageSetting?.fallback_img?.[imgUrls[index]] || '')
      case 'gif-thumb':
        return sThumb(sellpageSetting?.fallback_img?.[imgUrls[index]] || '')
      case 'gif-thumb-poster':
        return size
          ? sThumb(sellpageSetting?.fallback_img?.[imgUrls[index]] || '')
              .replace('.gif', '.webp')
              .replace('.webp', `_${size}.webp`)
          : sThumb(sellpageSetting?.fallback_img?.[imgUrls[index]] || '').replace('.gif', '.webp')
      default:
        return ''
    }
  }

  return (
    <div className="product-images">
      <style jsx global>
        {globalStyle}
      </style>
      <figure className="product-thumbnail is-square is-marginless mb-2 image">
        <>
          <>
            {!isNormalBattery ? (
              <>
                <picture key={syncedActiveIndex}>
                  <source data-srcset={getPathMedia('fallback-gif', syncedActiveIndex)} />
                  <img
                    className={`lazyload ${hashCode(imgUrls[syncedActiveIndex])}`}
                    src={getPathMedia('gif-thumb-poster', syncedActiveIndex)}
                    data-src={getPathMedia('fallback-gif', syncedActiveIndex)}
                    alt="carousel"
                  />
                </picture>
              </>
            ) : (
              <>
                {cacheImages?.map((videoRef) => {
                  if (imgUrls[videoRef]?.includes('.mp4')) {
                    return (
                      <video
                        className="video-vap"
                        playsInline
                        autoPlay
                        loop
                        muted
                        key={`prod-video-${videoRef}`}
                        poster={
                          getPathMedia('gif-thumb-poster', videoRef) ||
                          getPathMedia('gif-thumb', videoRef) ||
                          ''
                        }
                        style={{
                          display: videoRef === syncedActiveIndex ? 'block' : 'none'
                        }}
                      >
                        <source type="video/mp4" src={getPathMedia('video', videoRef)} />
                      </video>
                    )
                  }
                })}
              </>
            )}
          </>

          <>
            {cacheImages?.map((imageRef) => {
              if (!imgUrls[imageRef]?.includes('.mp4')) {
                return (
                  <picture
                    key={`prod-thumbnail-${imageRef}`}
                    style={{ display: imageRef === syncedActiveIndex ? 'block' : 'none' }}
                  >
                    <source data-srcset={getPathMedia('image', imageRef)} />
                    <source data-srcset={getPathMedia('fallback-image', imageRef)} />
                    <img
                      className={`lazyload ${hashCode(imgUrls[imageRef])}`}
                      src={getPathMedia('image-thumb', imageRef)}
                      data-src={getPathMedia('image', imageRef)}
                      alt="carousel"
                    />
                  </picture>
                )
              }
            })}
          </>
        </>
      </figure>
      {isMounted && (
        <ImageCarousel
          fallbackImgs={sellpageSetting?.fallback_img}
          imgUrls={imgUrls}
          activeIndex={syncedActiveIndex}
          changedVariantImage={changedVariantImage}
          onImageChange={changeImage}
        />
      )}
    </div>
  )
}

const globalStyle = css.global`
  .product-images {
    .product-thumbnail {
      margin-top: -0.75rem;
      margin-right: -0.75rem;
      margin-left: -0.75rem;

      img {
        object-fit: contain;
        background-color: white;
        border-radius: 5px;
      }
      video {
        object-fit: contain;
        background-color: white;
        border-radius: 5px;
      }
    }
  }
`
